import React from "react"
import { graphql, Link } from "gatsby"
import { Container, Themed } from "theme-ui"
import Layout from "../components/interactive-space/layout"
import MenuTasks from "../components/interactive-space/menu-tasks"
import BgLeft from "../images/bg-actividades-00.png"
import BgRight from "../images/bg-actividades-01.png"

const Actividades = ({ data }) => {

  const dataFragments = data.dataJson

  return(
    <Layout
      bgImageRight={BgRight}
      bgImageLeft={BgLeft}
    >
      <Container
        as={`main`}
        sx={{
          width: `100%`,
          paddingTop: 3,
          paddingRight: [0, 0, 0, 4, 4, 4],
          paddingLeft: [0, 0, 0, 4, 4, 4],
          h1: {
            color: `gray.6`,
            fontSize: 6,
          },
          "p, ul, li, a": {
            color: `gray.6`,
          },
          ".send-to-back": {
            marginBottom: 0,
          },
        }}
      >
        <Themed.h1>{dataFragments.fragment_1}</Themed.h1>
        <Themed.p dangerouslySetInnerHTML={{ __html: dataFragments.fragment_2}} />
        <Themed.p dangerouslySetInnerHTML={{ __html: dataFragments.fragment_3}} />
        <Themed.p dangerouslySetInnerHTML={{ __html: dataFragments.fragment_4}} />
        <MenuTasks />
        <Themed.p className="send-to-back"><Themed.a as={Link} to={`/${dataFragments.fragment_5}`}>&#8592; {dataFragments.fragment_6}</Themed.a></Themed.p>
      </Container>
    </Layout>
  )

}

export default Actividades

export const query = graphql`
  query Actividades {
    dataJson(slug: {eq: "page-actividades"}) {
      fragment_1
      fragment_2
      fragment_3
      fragment_4
      fragment_5
      fragment_6
    }
  }
`