import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Grid, Themed } from "theme-ui"
import getSlug from "speakingurl"

const MenuTasks = () => {

  const data = useStaticQuery(
    graphql`
      query MenuTasks {
        dataJson(slug: {eq: "menu-tasks"}) {
          fragment_1
          fragment_2
          fragment_3
          fragment_4
          fragment_5
        }
      }
    `
  )

  const dataFragments = data.dataJson

  const globalTask = getSlug(dataFragments.fragment_1)
  const contentMenu = [ getSlug(dataFragments.fragment_3), dataFragments.fragment_3 ]
  const linkToAllPictures = [ getSlug(dataFragments.fragment_5), dataFragments.fragment_5 ]

  return (
    <Grid
      as={`ul`}
      columns={[2, 2, 4, 4, 4, 4]}
      gap={[2, 3]}
      sx={{
        listStyle: `none`,
        margin: 0,
        padding: 0,
        li: {
          display: `block`,
          marginTop: 1,
          marginBottom: 1,
        },
        a: {
          backgroundColor: `tertiary`,
          border: 0,
          color: `gray.6`,
          fontSize: [2, 2, 2, 2, 3, 3],
          display: `grid`,
          height: [12, 12, 12, 16, 16, 24],
          placeItems: `center center`,
          textAlign: `center`,
          width: `100%`,
          "&:hover, &:active, &:focus": {
            border: 0,
            color: `gray.6`,
          },
        }
      }}
    >
      <Themed.li>
        <Themed.a href={`/${dataFragments.fragment_4}-${globalTask}`} rel="bookmark" className="link-to-presentation">{dataFragments.fragment_1}</Themed.a>
      </Themed.li>
      {[...Array(10)].map((item, i) =>
        <Themed.li key={i} >
          <Themed.a href={`${dataFragments.fragment_2}/${contentMenu[0]}-${i+1}`} rel="bookmark" className="link-to-presentation">{dataFragments.fragment_3} {i+1}</Themed.a>
        </Themed.li>
      )}
      <Themed.li>
        <Themed.a href={`/${linkToAllPictures[0]}`} rel="bookmark" className="link-to-presentation">{linkToAllPictures[1]}</Themed.a>
      </Themed.li>
    </Grid>
  )

}

export default MenuTasks